/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"

function SEO({
  description,
  lang,
  meta,
  title,
  canonical,
  noIndex,
  ldJsonFAQ,
}) {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `https://vtcsz2c-r4lha2oo.s3.amazonaws.com/static/dynobase_banner.png`,
        },
        {
          property: `twitter:image`,
          content: `https://vtcsz2c-r4lha2oo.s3.amazonaws.com/static/dynobase_banner.png`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: `@dynobase`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:domain`,
          content: "https://dynobase.dev",
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ].concat(meta)}
    >
      {noIndex && <meta name="robots" content="noindex" />}
      {canonical && <link rel="canonical" href={canonical} />}
      {ldJsonFAQ && <script type="application/ld+json">{ldJsonFAQ}</script>}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

export default SEO

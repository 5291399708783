import React from 'react';
import { Link } from "gatsby"

const InternalLink = ({to, children, color, ...otherProps}) => (
    <Link to={to}
    style={{ 
        color: color ? color : null 
    }}
    {...otherProps}
    >{children}</Link>       
);

export default InternalLink;
import { Link } from "gatsby"
import { Text } from "rebass"
import React from "react"
import Button from "./button"
import { ExternalLink } from '@fragments'
import { twitter, newsletter, tutorials } from "@constants/urls.js"

const icon = require("../images/icon-transparent.png")

const Header = ({ siteTitle }) => (
  <header
    style={{
      backgroundColor: "#2680C2",
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex" }}>
        <img
          src={icon}
          style={{ height: 44, marginBottom: 0 }}
          alt="dynobase-icon"
        />
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
            alignSelf: "center",
          }}
        >
          <Text
            style={{
              fontSize: "1.6rem",
              lineHeight: "1.5",
              color: "white",
              fontWeight: "bold",
              marginLeft: "8px",
              letterSpacing: -1.3,
            }}
          >
            {siteTitle}
          </Text>
        </Link>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Text
          sx={{ marginLeft: "6px", display: ["none", "initial", "initial"] }}
        >
          <a
            href="mailto:hello@esworkbench.com"
            style={{
              color: `white`,
              textDecoration: `none`,
              marginRight: 20,
              fontWeight: 500,
            }}
          >
            Contact
          </a>
        </Text>
      </div>
    </div>
  </header>
)

export default Header

module.exports = {
  home: "/",
  support: "/support/",
  features: "/#features",
  pricing: "/#pricing",
  newsletter: "/newsletter/",
  download: "/download",
  buyProduct: "/buy/",
  tutorials: "/elasticsearch-tutorials/",
  twitter: "https://twitter.com/esworkbench",
  memberPortal: "https://member.esworkbench.com/",
  privacyPolicy: "",
  eula: "",
  feature: {},
  learn: {},
  freeTools: {},
}

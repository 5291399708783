import React from "react"
import { ExternalLink, InternalLink } from "@fragments"

const FooterLink = ({ to, children, color, text, type, ...otherProps }) => {
  if (type === "external") {
    return (
      <li style={{ marginBottom: "4px" }}>
        <ExternalLink
          to={to}
          {...otherProps}
          style={{
            textDecoration: "none",
            color: "white",
            fontFamily: "helvetica, sans-serif",
            fontSize: "16px",
          }}
        >
          {text}
        </ExternalLink>
      </li>
    )
  } else if (type === "internal") {
    return (
      <li style={{ marginBottom: "4px" }}>
        <InternalLink
          to={to}
          {...otherProps}
          style={{
            textDecoration: "none",
            color: "white",
            fontFamily: "helvetica, sans-serif",
            fontSize: "16px",
          }}
        >
          {text}
        </InternalLink>
      </li>
    )
  } else {
    return (
      <a
        href={to}
        style={{
          textDecoration: "none",
          color: "white",
          fontFamily: "helvetica, sans-serif",
          fontSize: "16px",
        }}
      >
        Affiliates
      </a>
    )
  }
}

export default FooterLink

import React from "react"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Button = ({ children, href, to, primary, style, onClick, linkClassName, buttonClassName }) => (
  <button
    onClick={onClick}
    className={buttonClassName}
    style={{
      backgroundColor: primary ? "#FCE588" : "#B44D12",
      color: primary ? "#B44D12" : "#FCE588",
      border: "none",
      padding: "10px 20px",
      borderRadius: 5,
      fontWeight: 600,
      boxShadow:
        "0 2px 4px 0 rgba(136, 144, 195, 0.2), 0 5px 15px 0 rgba(37, 44, 97, 0.35)",
      ...style,
    }}
  >
    {href && (
      <OutboundLink
        style={{
          textDecoration: "none",
          color: primary ? "#B44D12" : "#FCE588",
          alignItems: "center",
          display: "flex",
        }}
        rel="noopener"
        href={href}
        className={linkClassName}
      >
        {children}
      </OutboundLink>
    )}
    {to && <Link to={to}>{children}</Link>}
    {!to && !href && children}
  </button>
)

export default Button
